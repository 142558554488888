@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Code syntax highlighting, powered by https://highlightjs.org */
@import 'highlight.js/styles/a11y-light.css';

@import 'base';
/* @import 'mailchimp'; */
@import 'custom';

@import 'tailwindcss/utilities';
